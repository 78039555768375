import dayjs from 'dayjs';

export function isNew(date) {
    const published = dayjs(date);
    const dayRange = process.env.NEXT_PUBLIC_NEW_DAYS;
    const cutoffDate = dayjs().subtract(dayRange, 'days');

    return published.isAfter(cutoffDate);
}

// Lodash doesn't support custom sort functions[0], so we have to do it manually[1,2]
// [0] https://github.com/lodash/lodash/pull/3764 (merged, but not released in 4.17.15)
// [1] https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_sortby-and-_orderby
// [2] https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
export function sortBy(key) {
    return (a, b) => a[key].localeCompare(b[key], 'de', { sensitivity: 'base' })
}

// Detect WebP support in the browser
export function canIUseWebp() {
    if (typeof window === 'undefined') return false;

    let support = true;

    try {
        const canvas = document.createElement('canvas');

        if (!!(canvas.getContext && canvas.getContext('2d'))) {
            support = canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }
    } catch (err) {
        support = false;
    }

    return support;
}