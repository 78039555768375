import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import Layout from '@/components/layout';
import { canIUseWebp } from '@/helpers/HelperFunctions';

export default function Home() {
    const { t } = useTranslation('common');

    const [headerClasses, setHeaderClasses] = useState('header start-hero');

    useEffect(() => {
        setHeaderClasses(
            classNames('header start-hero', {
                'no-webp': !canIUseWebp()
            })
        )
    }, []);

    return (
        <Layout title="Krankenhaus Datenplattform">

            <div className={headerClasses}>
                <div className="container pt-5 pb-5">
                    <div className="row pt-4 pt-md-7 pb-3 justify-content-center">
                        <div className="col-12 col-lg-11 text-center text-white">
                            <h1 className="display-2">Eine Datenplattform<br />
                                unbegrenzte Analysen und Benchmarks</h1>
                        </div>
                    </div>
                    <div className="row pb-4 pb-md-7 justify-content-center">
                        <div className="col-11 col-lg-6 pt-6 text-center text-white">
                            <p className="d-none">HYAPP verändert das Krankenhaus-Controlling nachhaltig, schafft Transparenz und verbindet. Was das konkret für Sie bedeutet?</p>
                            <Link href="/apps">
                                <a className="btn btn-white mr-3 px-4">Zu den Apps</a>
                            </Link>
                            <Link href="/contact?partner-werden">
                                <a className="btn btn-outline-white px-4">Partner werden</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center mt-6 mb-5">
                    <div className="col-10 col-md-6 text-center">
                        <h2 className="display-4">Warum HYAPP?</h2>
                        <p>HYAPP verändert das Krankenhaus-Controlling nachhaltig, schafft Transparenz und verbindet. Was das konkret für Sie bedeutet?</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-6 col-lg-5">
                        <div className="card card-fill">
                            <div className="card-body mt-0">
                                <h3 className="h2"><i className="fe fe-filter fe-align-bottom mr-3"></i> Datensilos versus effizientes Arbeiten</h3>
                                <p className="small">Unflexible KIS-Systeme und eine Vielzahl an Subsystemen erschweren ein effizientes Arbeiten im Krankenhauscontrolling. HYAPP führt die Daten aller wesentlichen Systeme Ihres Krankenhauses zusammen, denn gemeinsam mit unseren Partnern bieten automatisierte Datenimporte mit standardisierten REST-API Schnittstellen zu allen gängigen KIS, Subsystemen und Data Warehouses.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-5">
                        <div className="card card-fill">
                            <div className="card-body mt-0">
                                <h3 className="h2"><i className="fe fe-cloud-lightning fe-align-bottom mr-3"></i> Challenges im Klinikcontrolling</h3>
                                <p className="small">Begrenzte Ressourcen, zunehmende Komplexität der Vergütung und steigende Anforderungen an die Steuerung sind keine neuen Herausforderungen im Krankenhauscontrolling. HYAPP adressiert diese und bietet Best-Practice-Tools für die wichtigsten Analysen und Fragestellungen im Spannungsfeld zwischen Medizin und Ökonomie auf einer Plattform an.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-5">
                        <div className="card card-fill">
                            <div className="card-body mt-0">
                                <h3 className="h2"><i className="fe fe-eye fe-align-bottom mr-3"></i> Benchmarking erhellt blinde Flecke</h3>
                                <p className="small">Die Komplexität eines Krankenhauses mit einer Vielzahl an unterschiedlichsten Datenquellen aus Leistungs-, Funktions- und Querschnittsbereichen ist mit kaum einer anderen Branche vergleichbar. Dabei alles im Blick zu behalten ist enorm schwierig.  Mit HYAPP können alle relevanten Leistungsbereiche Ihres Klinikums analysiert und über ein  aussagefähiges Benchmarking validiert werden.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-5">
                        <div className="card card-fill">
                            <div className="card-body mt-0">
                                <h3 className="h2"><i className="fe fe-file-text fe-align-bottom mr-3"></i> Datenqualität schafft Transparenz</h3>
                                <p className="small">Eine objektive Diskussion von Handlungsfeldern und das Erkennen von Veränderungsbedarfen ist Kerngeschäft im Klinikcontrolling und steht nicht selten unter großen Restriktionen. In der Kommunikation mit Ihren Verantwortlichen sind eine hohe Datenqualität ein wichtiger Erfolgsfaktor und Ausgangspunkt für dynamische Veränderungsprozesse in Ihrem Klinikum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-dark text-white mt-6">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 px-0 py-5 text-center">
                            <h2 className="display-3 mt-4">HYAPP ermöglicht neue Dimensionen im Krankenhaus-Management</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-white py-6">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-10 col-md-6 text-center">
                            <h2 className="display-4">Welche Vorteile Ihnen HYAPP bringt</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9">
                            <div className="row justify-content-center">
                                <div className="col-6 col-md-4 text-center">
                                    <div className="avatar mb-3">
                                        <span className="avatar-title bg-primary rounded-circle"><i className="fe fe-pocket text-white h2 mb-0"></i></span>
                                    </div>
                                    <h3 className="">Sicher ist sicher</h3>
                                    <p className="small text-secondary">Hohe Standards an den Datenschutz und Datensicherheit sind für uns selbstverständlich.</p>
                                </div>
                                <div className="col-6 col-md-4 text-center">
                                    <div className="avatar mb-3">
                                        <span className="avatar-title bg-primary rounded-circle"><i className="fe fe-share-2 text-white h2 mb-0"></i></span>
                                    </div>
                                    <h3 className="">Vernetztes Wissen</h3>
                                    <p className="small text-secondary">Sie erhalten Zugang zu einer Community als Experten-Netzwerk.</p>
                                </div>
                                <div className="col-6 col-md-4 text-center">
                                    <div className="avatar mb-3">
                                        <span className="avatar-title bg-primary rounded-circle"><i className="fe fe-compass text-white h2 mb-0"></i></span>
                                    </div>
                                    <h3 className="">Mehr Überblick</h3>
                                    <p className="small text-secondary">Die neue anbieterübergreifende Vernetzung ermöglicht neue innovative Betrachtungen.</p>
                                </div>
                                <div className="col-6 col-md-4 text-center">
                                    <div className="avatar mb-3">
                                        <span className="avatar-title bg-primary rounded-circle"><i className="fe fe-monitor text-white h2 mb-0"></i></span>
                                    </div>
                                    <h3 className="">Die Plattform</h3>
                                    <p className="small text-secondary">Sie erhalten ein komplementäres Leistungsangebot der Top-Software-Analyse-Anbieter im Klinikmarkt verknüpft in einem Portal.</p>
                                </div>
                                <div className="col-6 col-md-4 text-center">
                                    <div className="avatar mb-3">
                                        <span className="avatar-title bg-primary rounded-circle"><i className="fe fe-search text-white h2 mb-0"></i></span>
                                    </div>
                                    <h3 className="">Entdecken Sie mehr</h3>
                                    <p className="small text-secondary">Freemium-Inhalte von HYAPP ergänzen Ihre Auswertungsmöglichkeiten und es werden regelmäßig neue Tools vorgestellt.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center mt-6 mb-5">
                    <div className="col-10 col-md-8 col-lg-6 text-center">
                        <h2 className="display-4">Unsere Partner</h2>
                        <p className="mb-5">Was uns verbindet ist eine langjährige Krankenhausexpertise, ein hoher Anspruch an datengesteuerten Analyse- und Workflow-Tools und ein gemeinsamer Datenpool, der Ihnen unbegrenzte Benchmarks eröffnet.<br />
                            <br />
                            Zu den Plattformpartnern gehören sowohl KIS-unabhängige IT-Softwareanbieter als auch spezialisierte Beratungsfirmen.</p>
                        <Link href="/apps">
                            <a className="btn btn-primary mr-3 px-4">Zu den Apps</a>
                        </Link>
                        <Link href="/contact?partner-werden">
                            <a className="btn btn-outline-primary px-4">Partner werden</a>
                        </Link>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-11 mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="mr-3 mr-md-4 mr-lg-5">
                                <Image src="/img/partners/damedic.png" alt="dametic" width="174" height="51" />
                            </div>
                            <div className="mx-3 mx-md-4 mx-lg-5">
                                <Image src="/img/partners/drg-control.png" alt="DRG-Control" width="197" height="51" />
                            </div>
                            <div className="mx-3 mx-md-4 mx-lg-5">
                                <Image src="/img/partners/mediio.png" alt="Mediio" width="162" height="62" />
                            </div>
                            <div className="mx-3 mx-md-4 mx-lg-5">
                                <Image src="/img/partners/bindoc.png" alt="BinDoc" width="120" height="60" />
                            </div>
                            <div className="ml-3 ml-md-4 ml-lg-5">
                                <Image src="/img/partners/atacama.png" alt="Atacama Software" width="150" height="76" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 mt-5">
                        <div className="d-flex justify-content-around align-items-center">
                            <div className="mr-3 mr-md-4 mr-lg-5">
                                <Image src="/img/partners/exaris.png" alt="Exaris" width="124" height="38" />
                            </div>
                            <div className="mx-3 mx-md-4 mx-lg-5">
                                <Image src="/img/partners/inspiring-health.png" alt="Inspiring-Health" width="260" height="58" />
                            </div>
                            <div className="mx-3 mx-md-4 mx-lg-5">
                                <Image src="/img/partners/borchers-kollegen.png" alt="Borchers & Kollegen" width="357" height="17" />
                            </div>
                            <div className="ml-3 ml-md-4 ml-lg-5">
                                <Image src="/img/partners/consus.png" alt="Consus" width="161" height="45" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-dark text-white mt-6">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6 px-0 py-5 text-center">
                            <h2 className="display-3 mt-4">Finden  Sie jetzt die passende
                                App für Ihr Krankenhaus!</h2>
                            <Link href="/apps">
                                <a className="btn btn-white px-4 my-4">Zu den Apps</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center mt-6 mb-5">
                    <div className="col-10 col-md-8 col-lg-6 text-center">
                        <h2 className="display-4">Über HYAPP</h2>
                        <p className="mb-5">Die Komplexität der Managementprozesse und der Wettbewerbsdruck nimmt seit Jahren im Krankenhaus sukzessive zu.</p>
                    </div>
                </div>
                <div className="row justify-content-around mb-7">
                    <div className="col-12 col-md-7 col-lg-5">
                        <p>Markt- und Wettbewerbsanalysen, Leistungsanalysen, Deckungsbeitragsanalysen auf Fall-DRG- und Abteilungsebene, spezifisches Verbrauchscontrolling und spezialisierte Workflow-Lösungen zur Kodierung und zur Unterstützung des Patientenmanagements sind in der Kliniksteuerung nicht mehr wegzudenken. Doch häufig sind dies Stand-Alone-Lösungen, die kaum Interoperabilität bieten und dadurch erheblichen Mehraufwand für das Controlling bedeuten.<br />
                            <br />
                            HYAPP verbindet den Nutzen einzelner Controlling-Lösungen auf einer Plattform und schafft so eine vernetzte Datenbasis, die Zeit spart, unbegrenzte Benchmarks eröffnet und durch die Verknüpfung von Tools und Analysen eine neue Dimension an validen Entscheidungsgrundlagen möglich macht. </p>
                    </div>
                    <div className="d-none d-md-block col-md-5">
                        <Image src="/img/about-01.png" alt="Über HYAPP" width="451" height="328" />
                    </div>
                </div>
                <div className="row justify-content-around mb-7">
                    <div className="d-none d-md-block col-5 col-lg-4">
                        <Image src="/img/about-02.png" alt="Bedarfsgerecht und zielgerichtet" width="356" height="280" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                        <h3 className="h1">Bedarfsgerecht und zielgerichtet</h3>
                        <p>Über eine einzigartige und qualitätsgesicherte Technologie, erhalten Sie Zugriff auf eine Plattform, die Ihnen eine breite Auswahl an Controlling- und Workflow-Applikationen bietet. Sie wählen das was Sie brauchen. Bedarfsgerecht und zielgerichtet. Und erhalten alle datengetriebenen Tools verknüpft aus einer Hand.</p>
                    </div>
                </div>
                <div className="row justify-content-around">
                    <div className="col-12 col-md-6 col-lg-5">
                        <h3 className="h1">Volle Datensicherheit & Fokus auf das Wesentliche</h3>
                        <p>Den hohen Anforderungen an Datenschutz und Datensicherheit Ihrer sehr sensiblen Krankenhausdaten wird HYAPP über eine mehrschichtige Sicherheitsinfrastruktur mit modernen Verschlüsselungstechnologien für den Transport und die Persistierung der Daten stets gerecht, ohne den vollen Funktionsumfang von HYAPP einschränken zu müssen.</p>
                    </div>
                    <div className="d-none d-md-block col-6 col-lg-5">
                        <Image src="/img/about-03.png" alt="Volle Datensicherheit" width="451" height="345" />
                    </div>
                </div>

                <div className="row justify-content-center my-5">
                    <div className="col-12 col-md-8">

                        {/* <!-- Card --> */}
                        <div className="card card-inactive">
                            <div className="card-body">

                                {/* <!-- Title --> */}
                                <h3 className="text-center">
                                    Sie haben Fragen oder Anregungen?
                                </h3>

                                {/* <!-- Text --> */}
                                <p className="text-muted text-center">
                                    Sie können uns jederzeit über die Kontaktseite erreichen.
                                </p>

                                {/* <!-- Button --> */}
                                <div className="text-center">
                                    <Link href="/contact">
                                        <a className="btn btn-outline-secondary">
                                            <i className="fe fe-mail mr-1 fe-align-bottom"></i> Kontakt
                                        </a>
                                    </Link>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const getStaticProps = async ({ locale }) => ({
    props: {
        ...await serverSideTranslations(locale, ['common', 'menu']),
    },
    revalidate: 10
});
